import { LocalStorageService, LocalStorage } from 'angular-web-storage';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';
import { Http, Headers, RequestOptions } from '@angular/http';
import { ItemRistoranteModel } from '../gest-ristorante/itemRistorante.model';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ItemDisponibModel } from './itemDisponib.model';
import { interval, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'load-timepicker',
  template: `<ngb-timepicker [(ngModel)]="globalData.orarioRistorante"></ngb-timepicker>`
})
export class NgbdTimepickerBasic {
  constructor(public globalData: GlobalDataService) {
    this.globalData.orarioRistorante = { hour: null, minute: null };
  }

}


@Component({
  selector: 'app-gest-ristorante',
  templateUrl: './gest-ristorante.component.html',
  styleUrls: ['./gest-ristorante.component.css']
})
export class GestRistoranteComponent implements OnInit, OnDestroy {
  data: string;
  nascondiModalBody: boolean = false;
  presenteInAnagrafica: any = 'false';
  isIdUtentePresente: boolean = false;
  filter: string = '';
  filterTemp: string = '';
  public debouncerOrdini = new Subject<string>();
  elementoSelezionato: any;

  itemRistorante: ItemRistoranteModel;
  itemDisponibil: ItemDisponibModel;
  ristoranteForm: UntypedFormGroup;
  itemDisponibilForm: UntypedFormGroup;

  localDataRistPranzo: any;
  localDataRistCena: any;
  nomePersona;

  postiDisponibiliPranzo: any;
  postiDisponibiliCena: any;
  tokenValue: any;
  mostraErroreOrario: boolean = false;
  intervalToClean: any;

  constructor(public globalData: GlobalDataService, private http: Http, private storage: LocalStorageService) {
    this.itemRistorante = new ItemRistoranteModel();
    this.itemDisponibil = new ItemDisponibModel();

    if (this.data == null || this.data == '' || this.data == undefined) {
      var d = new Date();
      var mese = '' + (d.getMonth() + 1);
      var giorno = '' + d.getDate();
      var anno = d.getFullYear();
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;
      this.data = anno + "-" + mese + "-" + giorno;
    }

    this.globalData.dataSceltaFormatoIta = giorno + "-" + mese + "-" + anno;
    this.globalData.dataScelta = anno + "-" + mese + "-" + giorno;
    this.tokenValue = this.storage.get('token');
    this.debouncerOrdini.pipe(
      debounceTime(900),
      distinctUntilChanged())
      .subscribe(value => {
        this.filter = this.filterTemp;
      });
    this.globalData.getListaUtenti();

  }

  ngOnDestroy() {
    this.intervalToClean.unsubscribe();
  }

  resettaFiltro() {
    this.filter = "";
    this.filterTemp = "";
    this.globalData.itemsFiltrati = null;
  }
  valorizzaModale() {

    if (this.globalData.itemsFiltrati != '' && this.globalData.itemsFiltrati != undefined && this.globalData.itemsFiltrati != null) {
      this.itemRistorante.nome = this.globalData.itemsFiltrati[this.elementoSelezionato].nome;
      this.itemRistorante.cognome = this.globalData.itemsFiltrati[this.elementoSelezionato].cognome;
      this.itemRistorante.telefono = this.globalData.itemsFiltrati[this.elementoSelezionato].telefono;
      this.itemRistorante.idUtente = this.globalData.itemsFiltrati[this.elementoSelezionato].idUtente;
    } else {

      this.itemRistorante.nome = this.globalData.listaUtenti[this.elementoSelezionato].nome;
      this.itemRistorante.cognome = this.globalData.listaUtenti[this.elementoSelezionato].cognome;
      this.itemRistorante.telefono = this.globalData.listaUtenti[this.elementoSelezionato].telefono;
      this.itemRistorante.idUtente = this.globalData.listaUtenti[this.elementoSelezionato].idUtente;
    }

    //console.log("idutente: " + this.ombrelloneDataModel.idUtente);

  }



  toggleAlertNewBookings() {
    this.globalData.showAlertNewBookingsRist = !this.globalData.showAlertNewBookingsRist;
    this.globalData.dataUltimoOrdineRist = this.globalData.dataUltimoOrdineRist;
  }

  ngOnInit() {
    this.aggiornaDisponibilita(this.globalData.dataScelta);
    this.globalData.getPrenotazioniRistorante(this.globalData.dataScelta);

    this.intervalToClean = interval(120000).subscribe(x => { // con questa funzione aggiorno ogni tot secondi il contenuto della pagina "gestione ombrelloni" al fine di ridurre anomalie di prenotazione

      if (!this.globalData.modificaInCorso) {
        this.globalData.spinner.show();
        if (this.globalData.dataScelta == null || this.globalData.dataScelta == '' || this.globalData.dataScelta == undefined) {
          var d = new Date();
          var mese = '' + (d.getMonth() + 1);
          var giorno = '' + d.getDate();
          var anno = d.getFullYear();
          if (mese.length < 2) mese = '0' + mese;
          if (giorno.length < 2) giorno = '0' + giorno;
          this.globalData.dataScelta = anno + "-" + mese + "-" + giorno;
        }



        //console.log(this.globalData.isUserLogged);
        this.globalData.getPrenotazioniRistorante(this.globalData.dataScelta);

        //console.log("Sto aggiornado il contenuto in data " + this.globalData.dataScelta + " delle prenotazioni ristorante " + new Date());
      }
    });




    this.ristoranteForm = new UntypedFormGroup({
      nome: new UntypedFormControl('', [Validators.required, Validators.pattern('[àéèùòìa-zA-Z\' ]*'), Validators.minLength(3), Validators.maxLength(25)]),
      cognome: new UntypedFormControl('', [Validators.required, Validators.pattern('[àéèùòìa-zA-Z\' ]*'), Validators.minLength(2), Validators.maxLength(20)]),
      telefono: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(10)]),
      fasciaOraria: new UntypedFormControl('', [Validators.required]),
      orario: new UntypedFormControl('', [Validators.required]),
      nomePersona: new UntypedFormControl(''),
      numeroCoperti: new UntypedFormControl('', [Validators.required])

    });

    this.itemDisponibilForm = new UntypedFormGroup({
      fasciaOraria: new UntypedFormControl('', [Validators.required]),
      numeroCoperti: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9 ]*')])

    });
  }

  resettaOrario() {
    this.itemRistorante.orario = "";
    if (this.itemRistorante.fasciaOraria != '' && this.itemRistorante.orario == '') {
      this.mostraErroreOrario = true;
    } else if (this.itemRistorante.fasciaOraria != '' && this.itemRistorante.orario != '') {
      this.mostraErroreOrario = false;
    } else if (this.itemRistorante.fasciaOraria == '' && this.itemRistorante.orario == '')
      this.mostraErroreOrario = true;
  }

  resettaErrore() {
    if (this.itemRistorante.fasciaOraria != '' && this.itemRistorante.orario == '') {
      this.mostraErroreOrario = true;
    } else if (this.itemRistorante.fasciaOraria != '' && this.itemRistorante.orario != '') {
      this.mostraErroreOrario = false;
    } else if (this.itemRistorante.fasciaOraria == '' && this.itemRistorante.orario == '')
      this.mostraErroreOrario = true;
  }


  preparaDeletePrenotazioneRistorante(item) {
    this.itemRistorante.idPrenotazioneRistorante = item.idPrenotazioneRistorante;
    this.itemRistorante.nome = item.utente.nome + " " + item.utente.cognome;
  }

  deletePrenotazioneRistorante(id) {
    // console.log("elimino la prenotazione " + id);


    this.globalData.spinner.show();


    this.tokenValue = this.storage.get('token');

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "idPrenotazioneRistorante": id

    }

    //  console.log(postData);

    this.http.post(this.globalData.serviziRest.deletePrenotazioneRist, postData, requestOptions)
      .subscribe(data => {

        setTimeout(function () {
          //    console.log("eliminazione ombrellone inserita");
          this.globalData.getPrenotazioniRistorante(this.globalData.dataScelta);
          this.globalData.spinner.hide();
          this.resetCampiModale();
        }.bind(this), 3000);

      }, error => {
        this.globalData.spinner.hide();
        // console.log(error.status);
        if (error.status == 0) {

        } else if (error.status == 400 || error.status == 401 || error.status == 403) {

        } else {

        }

        // console.log(error);
      });

  }
  modInCorso() {
    this.globalData.modificaInCorso = true;
    //  console.log(this.globalData.modificaInCorso);
  }

  resetCampiModale() {
    //  console.log("Sto resettando i campi della modale");
    this.presenteInAnagrafica = 'false';
    this.resettaFiltro();
    this.itemRistorante.reset();
    this.ristoranteForm.reset();
    this.itemDisponibilForm.reset();
    this.globalData.dataAddRistorante = "";
    this.globalData.dataEditRistorante = "";
    this.globalData.dataDispRistorante = "";
    this.globalData.ristoranteAddForm.reset();
    this.globalData.ristoranteEditForm.reset();
    this.globalData.ristoranteDispForm.reset();
    this.itemRistorante = new ItemRistoranteModel();
    this.globalData.modificaInCorso = false;
    this.mostraErroreOrario = false;
    this.nomePersona = '';

  }

  aggiungiPersona(nome) {
    this.itemRistorante.listaCommensali.push({ nome });
    this.nomePersona = '';
  }


  rimuoviPersonaDaLista(indice) {
    if (this.itemRistorante.listaCommensali.length > 0) {
      this.itemRistorante.listaCommensali.splice(indice, 1);
    }
  }



  addPrenotazioneRistorante(itemRistorante) {

    this.globalData.modificaInCorso = true;
    //  console.log("sono dentro add prenotazione ristorante");


    this.globalData.spinner.show();


    this.tokenValue = this.storage.get('token');

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    let dataScelta = this.globalData.dataAddRistorante.year + "-" + this.globalData.dataAddRistorante.month + "-" + this.globalData.dataAddRistorante.day; // prepararo la data con cui chiamare il servizio, nel formato aaaa-mm-gg

    this.itemRistorante.data = this.fixZeriData(this.globalData.dataAddRistorante);


    let postData = {
      "utente": {
        "idUtente": itemRistorante.idUtente,
        "nome": itemRistorante.nome,
        "cognome": itemRistorante.cognome,
        "telefono": itemRistorante.telefono,
      },
      "idTurno": itemRistorante.fasciaOraria,
      "orario": itemRistorante.orario,
      "nota": itemRistorante.note,
      "idFonte": 3, // idFonte = 3 significa prenotazione inviata da Gestionale
      "dataPrenotata": this.itemRistorante.data,
      "numeroCoperti": itemRistorante.coperti,
      "listaCommensali": itemRistorante.listaCommensali
    }

    this.http.post(this.globalData.serviziRest.insertPrenotazioneRist, postData, requestOptions)
      .subscribe(data => {

        // this.aggiornaDisponibilita();
        setTimeout(function () {
          this.globalData.spinner.hide();
          this.resetCampiModale();
          this.globalData.getPrenotazioniRistorante(this.globalData.dataScelta);

        }.bind(this), 3000);
      }, error => {
        setTimeout(function () {
          this.globalData.spinner.hide();

        }.bind(this), 3000);
        // console.log(error.status);
        // console.log(error);
      });

  }

  preparaModificaRistorante(item) {

    this.globalData.modificaInCorso = true;

    this.itemRistorante.listaCommensali = item.listaCommensali;
    this.itemRistorante.nome = item.utente.nome;
    this.itemRistorante.cognome = item.utente.cognome;
    this.itemRistorante.telefono = item.utente.telefono;
    this.itemRistorante.fasciaOraria = item.idTurno;
    this.itemRistorante.coperti = item.numeroCoperti;
    this.itemRistorante.orario = item.orario;
    this.itemRistorante.note = item.nota;
    this.itemRistorante.idUtente = item.idUtente;
    this.itemRistorante.idPrenotazioneRistorante = item.idPrenotazioneRistorante;

    //console.log(this.globalData.dataScelta);


    if (this.globalData.dataScelta != null && this.globalData.dataScelta != '') {
      var d = this.globalData.dataScelta.split("-");

      d[0] = d[0] * 1;


      if (d[1].substring(0, 1) == '0') {
        d[1].substring(0, 1).replace("0", "");
        d[1].trim();
      }
      d[1] = d[1] * 1;


      if (d[2].substring(0, 1) == '0') {
        d[2].substring(0, 1).replace("0", "");
        d[2].trim();
      }
      d[2] = d[2] * 1;

    }
    this.globalData.dataEditRistorante = {
      day: d[2],
      month: d[1],
      year: d[0],
    }


    // console.log(this.globalData.dataEditRistorante);

  }
  modificaPrenotazioneRistorante(itemRistorante) {

    if (this.globalData.dataEditRistorante.year != '' && this.globalData.dataEditRistorante.year != undefined && this.globalData.dataEditRistorante.year != '--') {
      this.itemRistorante.data = this.globalData.dataEditRistorante.year + "-" + this.globalData.dataEditRistorante.month + "-" + this.globalData.dataEditRistorante.day;

    }


    let dataDaModificare = null;
    dataDaModificare = this.itemRistorante.data.split("-");
    let mese = '' + dataDaModificare[1];
    let giorno = '' + dataDaModificare[2];
    let anno = '' + dataDaModificare[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificata = anno + "-" + mese + "-" + giorno;
    this.globalData.spinner.show();


    this.tokenValue = this.storage.get('token');

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });



    let postData = {
      "utente": {
        "idUtente": itemRistorante.idUtente,
        "nome": itemRistorante.nome,
        "cognome": itemRistorante.cognome,
        "telefono": itemRistorante.telefono,
      },
      "idPrenotazioneRistorante": itemRistorante.idPrenotazioneRistorante,
      "idTurno": itemRistorante.fasciaOraria,
      "idFonte": 3, // idFonte = 3 significa prenotazione inviata da Gestionale
      "dataPrenotata": dataModificata,
      "orario": itemRistorante.orario,
      "nota": itemRistorante.note,
      "numeroCoperti": itemRistorante.coperti,
      "listaCommensali": itemRistorante.listaCommensali
    }

    //console.log(postData);
    this.http.post(this.globalData.serviziRest.updatePrenotazioneRist, postData, requestOptions)
      .subscribe(data => {

        setTimeout(function () {
          this.globalData.spinner.hide();
          this.resetCampiModale();
          this.globalData.getPrenotazioniRistorante(this.globalData.dataScelta);

        }.bind(this), 3000);
      }, error => {
        setTimeout(function () {
          this.resetCampiModale();
          this.globalData.spinner.hide();

        }.bind(this), 3000);
        //  console.log(error.status);
        //  console.log(error);
      });

  }

  aggiornaDisponibilita(data) {
    this.globalData.getDisponibilitaPranzo(data);
    this.globalData.getDisponibilitaCena(data);

  }


  fixZeriData(data) {
    var data = data;
    var mese = '' + data.month;
    var giorno = '' + data.day;
    var anno = data.year
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return (anno + "-" + mese + "-" + giorno);
  }


  fixFormatoData(data) {
    var d = new Date();
    var mese = '' + data.month;
    var giorno = '' + data.day;
    var anno = data.year
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return (anno + "-" + mese + "-" + giorno);
  }

  cambiaDispRistorante(nuovaDisp) {

    this.globalData.spinner.show();
    this.itemDisponibil.data = this.fixZeriData(this.globalData.dataDispRistorante);


    this.itemDisponibil.fasciaOraria = nuovaDisp.fasciaOraria;
    this.itemDisponibil.numeroCoperti = nuovaDisp.numeroCoperti;

    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    headers.append('Content-Type', 'application/json');
    const requestOptions = new RequestOptions({ headers: headers });
    // console.log(this.itemDisponibil.data);
    let postData = {
      "data": this.itemDisponibil.data,
      "idTurno": this.itemDisponibil.fasciaOraria,
      "postiDisponibili": this.itemDisponibil.numeroCoperti
    }

    //console.log(postData);


    this.http.post(this.globalData.serviziRest.updateDisponibilitaRistorante, postData, requestOptions)
      .subscribe(data => {

        this.aggiornaDisponibilita(this.itemDisponibil.data);
        setTimeout(function () {
          this.globalData.spinner.hide();
          //  console.log(this.listaUtenti);
        }.bind(this), 1000);
      }, error => {
        setTimeout(function () {
          this.globalData.spinner.hide();
          // console.log(this.listaUtenti);
        }.bind(this), 1000);
        //  console.log(error.status);
        //  console.log(error);
      });


  }
}
