import { Component } from '@angular/core';
import { GlobalDataService } from './providers/global-data.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateFRParserFormatter } from "./ngb-date-fr-parser-formatter"


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class AppComponent {
  title = 'gestionale-sunset';
  isNatale: boolean = false;
  constructor(public globalData: GlobalDataService) {
    this.checkNatale();

    if (!this.globalData.modificaInCorso && this.globalData.isUserLogged) {
      this.globalData.spinner.show();
      if (this.globalData.dataScelta == null || this.globalData.dataScelta == '' || this.globalData.dataScelta == undefined) {
        var d = new Date();
        var mese = '' + (d.getMonth() + 1);
        var giorno = '' + d.getDate();
        var anno = d.getFullYear();
        if (mese.length < 2) mese = '0' + mese;
        if (giorno.length < 2) giorno = '0' + giorno;
        this.globalData.dataScelta = anno + "-" + mese + "-" + giorno;
      }

      console.log("Sto aggiornado il contenuto in data " + this.globalData.dataScelta + " della gestione ombrelloni, del riepilogo, delle prenotazioni ristorante e della lista utenti " + new Date());
    } else if (this.globalData.isUserLogged) {
      //   console.log("Modifiche in corso, non effettuo il refresh dei dati " + new Date());
    }

  }

  checkNatale() {
    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let data = yyyy + mm + dd;

    let finePeriodoNataleAttuale = null;
    let periodoNataleAttuale = null;

    if (mm == '01') {
      periodoNataleAttuale = yyyy - 1 + '12' + '08'; // es. 2019 - 12 - 08
      finePeriodoNataleAttuale = yyyy + '01' + '07'; // es. 2020 - 01 - 07 
    } else if (mm == '12') {
      periodoNataleAttuale = yyyy + '12' + '08'; // es 2020 - 12 - 08
      finePeriodoNataleAttuale = yyyy + 1 + '01' + '07';  // es. 2021 - 01 - 08
    }



    if (data >= periodoNataleAttuale && data < finePeriodoNataleAttuale) {
      this.isNatale = true;
    } else {
      this.isNatale = false;
    }
  }
}
