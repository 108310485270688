import { NgModule } from "@angular/core";
import { DatePickerComponent } from "./datepicker/datepicker-popup/datepicker-popup.component";
import {
    NgbdDatepickerI18n,
    NgbdDatepickerRange,
    NgbdDatepickerOmbr,
    NgbdDatepickerBloccofile,
    NgbdDatepickerOmbrFinoA,
    NgbdDatepickerOmbrOrd,
    NgbdDatepickerRist,
    NgbdDatepickerRistAdd,
    NgbdDatepickerRistEdit,
    NgbdDatepickerRistDisp,
    NgbdDatepickerOmbrOrdCompleto,
    NgbdDatepickerOmbrRiepilogoIncassi,
    NgbdDatepickerInizioStagione,
    NgbdDatepickerFineStagione,
    NgbdDatepickerFineStagioneRistorante,
    NgbdDatepickerInizioStagioneRistorante
} from "./datepicker/datepicker-ita/datepicker-ita.component";
import { NgbdTimepickerBasic } from "./gest-ristorante/gest-ristorante.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        DatePickerComponent,
        NgbdDatepickerI18n,
        NgbdDatepickerRange,
        NgbdDatepickerOmbr,
        NgbdDatepickerBloccofile,
        NgbdDatepickerInizioStagione,
        NgbdDatepickerFineStagione,
        NgbdDatepickerFineStagioneRistorante,
        NgbdDatepickerInizioStagioneRistorante,
        NgbdDatepickerOmbrFinoA,
        NgbdDatepickerOmbrOrd,
        NgbdDatepickerOmbrOrdCompleto,
        NgbdDatepickerOmbrRiepilogoIncassi,
        NgbdDatepickerRist,
        NgbdDatepickerRistAdd,
        NgbdDatepickerRistEdit,
        NgbdDatepickerRistDisp,
        NgbdTimepickerBasic,
    ],
    exports: [DatePickerComponent,
        NgbdDatepickerI18n,
        NgbdDatepickerRange,
        NgbdDatepickerOmbr,
        NgbdDatepickerBloccofile,
        NgbdDatepickerInizioStagione,
        NgbdDatepickerFineStagione,
        NgbdDatepickerFineStagioneRistorante,
        NgbdDatepickerInizioStagioneRistorante,
        NgbdDatepickerOmbrFinoA,
        NgbdDatepickerOmbrOrd,
        NgbdDatepickerOmbrOrdCompleto,
        NgbdDatepickerOmbrRiepilogoIncassi,
        NgbdDatepickerRist,
        NgbdDatepickerRistAdd,
        NgbdDatepickerRistEdit,
        NgbdDatepickerRistDisp,
        NgbdTimepickerBasic],

    imports: [
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        CommonModule],
})
export class DatePickerModule { }