// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyBL-2eVR-gvnTPNdcPzn4em2zqf5GtiJ7A",
    authDomain: "sunset-image-upload.firebaseapp.com",
    databaseURL: "https://sunset-image-upload.firebaseio.com",
    projectId: "sunset-image-upload",
    storageBucket: "sunset-image-upload.appspot.com",
    messagingSenderId: "158915171577",
    appId: "1:158915171577:web:08d2b4875fc0436006fe28",
    measurementId: "G-DX6JC8BDRZ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
