import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AngularWebStorageModule } from 'angular-web-storage';
import { SelezioneDinamicaDirective } from './direttive/selezione-dinamica.directive';
import { OmbrelloniModule } from './ombrelloni.module';
import { SharedModule } from './shared.module';
import { NgxSpinnerModule } from "ngx-spinner";
/* import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage"; */

/* ######################################################  */

const routes: Routes = [
  // Regular Route

  { path: 'anagrafica-clienti', loadChildren: () => import('./anagrafica-clienti/anagraficaClienti.module').then(m => m.AnagraficaClientiModule) },
  { path: 'anagrafica-rivenditori', loadChildren: () => import('./anagrafica-rivenditori/anagraficaRivenditori.module').then(m => m.AnagraficaRivenditoriModule) },
  { path: 'servizi-lido', loadChildren: () => import('./servizi-lido/serviziLido.module').then(m => m.ServiziLidoModule) },
  { path: 'e-commerce', loadChildren: () => import('./e-commerce/e-commerce.module').then(m => m.ECommerceModule) },
  { path: 'gest-ristorante', loadChildren: () => import('./gest-ristorante/gestioneRistorante.module').then(m => m.GestioneRistoranteModule) },
  { path: 'gest-ordini-bar', loadChildren: () => import('./gest-ordini-bar/gestioneOrdiniBar.module').then(m => m.GestioneOrdiniBarModule) },
  { path: 'storico-ordini', loadChildren: () => import('./storico-ordini/storicoOrdini.module').then(m => m.StoricoOrdiniModule) },
  { path: 'storico-ordini-completo', loadChildren: () => import('./storico-ordini-completo/storicoOrdiniCompleto.module').then(m => m.StoricoOrdiniCompletoModule) },
  { path: 'riepilogo-incassi', loadChildren: () => import('./riepilogo-incassi/riepilogo-incassi.module').then(m => m.RiepilogoIncassiModule) },
  { path: 'news-page', loadChildren: () => import('./news-page/news.module').then(m => m.NewsModule) },
  { path: 'profilo', loadChildren: () => import('./profilo/profilo.module').then(m => m.ProfiloModule) },
  { path: 'live-webcam', loadChildren: () => import('./live-webcam/live-webcam.module').then(m => m.LiveWebcamModule) },
  { path: 'rivenditori', loadChildren: () => import('./rivenditori/rivenditori.module').then(m => m.RivenditoriModule) },
  { path: 'app-login', component: LoginComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SelezioneDinamicaDirective,


  ],
  imports: [
    BrowserModule,
    HttpModule,
    BrowserAnimationsModule,
    SharedModule,
    OmbrelloniModule,
    NgxSpinnerModule,
    /*     AngularFireStorageModule,
        AngularFireModule.initializeApp(environment.firebaseConfig, "cloud"), */
    AngularWebStorageModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
