export class ItemRistoranteModel {

    nome: string;
    cognome: string;
    telefono: number;
    fasciaOraria: string;
    orario: string;
    coperti: number;
    data: string;
    note: string;
    idPrenotazioneRistorante: string;
    idUtente: string;
    listaCommensali = [];

    constructor() { }

    reset() {
        this.nome = "";
        this.cognome = "";
        this.telefono = null;
        this.fasciaOraria = "";
        this.orario = "";
        this.data = "";
        this.coperti = null;
        this.note = "";
        this.idPrenotazioneRistorante = "";
        this.idUtente = "";
        this.listaCommensali = [];
    }
}