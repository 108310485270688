import { Component } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';
import { Http } from '@angular/http';
import { LocalStorageService, LocalStorage } from 'angular-web-storage';
import { AuthService } from '../providers/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(public globalData: GlobalDataService, private http: Http, private storage: LocalStorageService, public auth: AuthService) {

  }

}
