import { Pipe, PipeTransform } from "@angular/core";
import { GlobalDataService } from "../providers/global-data.service";
import { distinct } from 'rxjs/operators';
import { from } from 'rxjs';
@Pipe({
    name: 'stringFilterOrdini',
})


export class StringFilterPipeOrdini implements PipeTransform {

    tempData: any[] = [];
    itemsFiltrati = [];

    constructor(public globalData: GlobalDataService) { }
    transform(items: any[], filter: string) {
        if ((!filter || filter === '') && (this.globalData.filtraOrdiniAnnulati == false && this.globalData.filtraOrdiniContanti == false && this.globalData.filtraOrdiniOnline == false)) {
            return items;
        }
        //console.log(items);
        for (let i = 0; i < items.length; i++) {


            if (this.globalData.filtraOrdiniAnnulati == true && this.globalData.filtraOrdiniContanti == false && this.globalData.filtraOrdiniOnline == false && filter.trim() == '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {

                    if (items[i].ombrelloni[j].annullato == 1) {
                        this.tempData.push(items[i]);
                    }

                }
            } else if (this.globalData.filtraOrdiniAnnulati == false && this.globalData.filtraOrdiniContanti == true && this.globalData.filtraOrdiniOnline == false && filter.trim() == '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {

                    if (items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null) {
                        this.tempData.push(items[i]);
                    }

                }
            }
            else if (this.globalData.filtraOrdiniAnnulati == false && this.globalData.filtraOrdiniContanti == false && this.globalData.filtraOrdiniOnline == true && filter.trim() == '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {

                    if (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null) {
                        this.tempData.push(items[i]);
                    }
                }
            }
            else if (this.globalData.filtraOrdiniAnnulati == true && this.globalData.filtraOrdiniContanti == false && this.globalData.filtraOrdiniOnline == false && filter.trim() != '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {
                    if ((items[i].utente.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                        || (items[i].utente.cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                        || (items[i].utente.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                        || ((items[i].ombrelloni[j].idPrenotazioneOmbrellone + "").indexOf(filter) !== -1)
                        || ((items[i].ombrelloni[j].idOrdine + "").indexOf(filter) !== -1)
                        || (items[i].annullato == 1)
                        || (items[i].utente.telefono.indexOf(filter) !== -1)) {
                        this.tempData.push(items[i]);
                    }
                }
            }
            else if (this.globalData.filtraOrdiniAnnulati == false && this.globalData.filtraOrdiniContanti == true && this.globalData.filtraOrdiniOnline == false && filter.trim() != '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {
                    if ((items[i].utente.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || (items[i].utente.cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || (items[i].utente.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || ((items[i].ombrelloni[j].idPrenotazioneOmbrellone + "").indexOf(filter) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || ((items[i].ombrelloni[j].idOrdine + "").indexOf(filter) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || (items[i].annullato == 1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)
                        || (items[i].utente.telefono.indexOf(filter) !== -1 && items[i].idPagamentoBraintree == null && items[i].idPagamentoPaypal == null && items[i].idPagamentoStripe == null)) {
                        this.tempData.push(items[i]);
                    }
                }
            }
            else if (this.globalData.filtraOrdiniAnnulati == false && this.globalData.filtraOrdiniContanti == false && this.globalData.filtraOrdiniOnline == true && filter.trim() != '') {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {
                    if ((items[i].utente.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || (items[i].utente.cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || (items[i].utente.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || ((items[i].ombrelloni[j].idPrenotazioneOmbrellone + "").indexOf(filter) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || ((items[i].ombrelloni[j].idOrdine + "").indexOf(filter) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || (items[i].annullato == 1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))
                        || (items[i].utente.telefono.indexOf(filter) !== -1 && (items[i].idPagamentoBraintree != null || items[i].idPagamentoPaypal != null || items[i].idPagamentoStripe != null))) {
                        this.tempData.push(items[i]);
                    }
                }
            } else

                if (this.globalData.filtraOrdiniMattina == true && this.globalData.filtraOrdiniPomeriggio == false) {

                    if (this.globalData.filtraOrdiniAnnulati == true) {
                        for (let j = 0; j < items[i].ombrelloni.length; j++) {

                            if (items[i].ombrelloni[j].annullato == 1 && items[i].ombrelloni[j].idFasciaOraria == 2) {
                                this.tempData.push(items[i]);
                            }
                        }
                    } else if (this.globalData.filtraOrdiniAnnulati == false) {
                        for (let j = 0; j < items[i].ombrelloni.length; j++) {

                            if (items[i].ombrelloni[j].idFasciaOraria == 2) {
                                this.tempData.push(items[i]);
                            }
                        }
                    }




                } else if (this.globalData.filtraOrdiniMattina == false && this.globalData.filtraOrdiniPomeriggio == true) {

                    if (this.globalData.filtraOrdiniAnnulati == true) {
                        for (let j = 0; j < items[i].ombrelloni.length; j++) {

                            if ((items[i].ombrelloni[j].annullato == 1 && items[i].ombrelloni[j].idFasciaOraria == 3) || (items[i].ombrelloni[j].annullato == 1 && items[i].ombrelloni[j].idFasciaOraria == 4)) {
                                this.tempData.push(items[i]);
                            }
                        }
                    } else if (this.globalData.filtraOrdiniAnnulati == false) {
                        for (let j = 0; j < items[i].ombrelloni.length; j++) {

                            if (items[i].ombrelloni[j].idFasciaOraria == 3 || items[i].ombrelloni[j].idFasciaOraria == 4) {
                                this.tempData.push(items[i]);
                            }
                        }
                    }


                }
                else if (filter.trim() != '') {
                    for (let j = 0; j < items[i].ombrelloni.length; j++) {
                        if ((items[i].utente.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                            || (items[i].utente.cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                            || (items[i].utente.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                            || ((items[i].ombrelloni[j].idPrenotazioneOmbrellone + "").indexOf(filter) !== -1)
                            || ((items[i].ombrelloni[j].idOrdine + "").indexOf(filter) !== -1)
                            || (items[i].annullato == 1)
                            || (items[i].utente.telefono.indexOf(filter) !== -1)) {
                            this.tempData.push(items[i]);
                        }
                    }
                }

        }

        this.itemsFiltrati = [];
        from(this.tempData).pipe(
            distinct()
        ).subscribe(element => {
            this.itemsFiltrati.push(element);
        });

        this.tempData = [];
        return this.itemsFiltrati;
    }
}